<template lang="pug">
  div#config-price(fluid='' tag='section')
    //- snackbar para error por no haber seleccionado diente
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(v-model='snackbar.show' :color='snackbar.color' top='' right='')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' icon='' @click='snackbar.show = false')
    //-     v-icon mdi-close
    v-dialog(v-model='confirmationDialog' persistent='persistent' max-width='290')
      v-card
        v-card-title.headline
          | Confirmar
        v-card-text
          | Est&aacute;s seguro de eliminar este procedimiento?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialog=false')
              | Cancelar
            v-btn(color='blue darken-1' @click='acceptConfirmation')
              | Aceptar
    v-row(justify='center')
      v-col(cols='12' lg='12')
        base-material-card(style='padding-top: 0px; margin:0px; padding-bottom:0px;')
          template(v-slot:heading='')
            .display-2.font-weight-light
              | Configuración de Procedimientos
          //- v-container#process-table(fluid='' tag='section' style='padding:0;')
          v-row
            v-col(cols='12' md='6' style='padding:5px;')
              p.display-2.font-weight-light
                  | Todos los procedimientos
              v-text-field.purple-input(v-model='searchProcessTable' append-icon='mdi-magnify' label='Buscar procedimientos disponibles' single-line='' hide-details='' style='padding: 0px; margin-bottom: 15px;' @keyup='searchProcess')
              v-row(style='height: 50vh')
                v-col#precios-procedimientos-1-view.elevation-2(cols='12' style='padding:5px; margin-right:0px; margin-left:0px; margin-bottom: 20px; overflow: auto')
                  v-simple-table(style='height: 100px;' scrollable='')
                    thead
                      tr
                        th.primary--text
                          | Añadir
                        th.primary--text
                          | Procedimiento
                        th.primary--text
                          | Categor&iacute;a
                    tbody
                      tr(v-for='(process, index) in processFilter' :key='index')
                        td
                          v-btn(v-if="user.selectAccount === 'personal' || user.accounts.length === 1" icon='' color='blue' @click='addProcess(process)')
                            v-icon(style='font-size:30px;')
                              | mdi-plus-circle
                        td
                          | {{ process.label }}
                        td
                          | {{ process.category }}
            v-col(cols='12' md='6' style='padding:5px;')
              p.display-2.font-weight-light
                  | Procedimientos agregados
              v-text-field.purple-input(v-model='searchProcessTableAdded' append-icon='mdi-magnify' label='Buscar procedimientos agregados' single-line='' hide-details='' style='padding: 0px; margin-bottom: 15px;' @keyup='searchProcessAdded')
              v-row(style='height: 50vh')
                v-col#precios-procedimientos-2-view.elevation-2(cols='12' style='padding:5px; margin-bottom:20px; overflow: auto;')
                  v-row(v-show='processesAddedArray.length === 0' justify='center' style='margin: 5px;')
                    p(style='text-align: center;')
                      | No hay procedimientos agregados, selecciona la flecha del tratamiento que desees agregar
                  v-simple-table(v-show='processesAddedArray.length !== 0' style='height: 100px;' scrollable='')
                    thead
                      tr
                        th.primary--text
                          | Procedimiento
                        th.primary--text
                          | Precio Unitario
                        th.primary--text
                          | Categor&iacute;a
                        th.primary--text
                          | Quitar
                    tbody
                      tr(v-for='(addedProcess, index) in processesAddedFilter' :key='index')
                        td
                          | {{ addedProcess.label }}
                        td
                          v-text-field.purple-input(v-model='addedProcess.price' label='Precio' single-line='' hide-details='' style='padding: 0px; margin: 0px;' type='number' :disabled='disabledText')
                        td
                          | {{ addedProcess.category }}
                        td(text-center='')
                          v-btn(v-if="user.selectAccount === 'personal' || user.accounts.length === 1" icon='' color='red' @click='deleteProcess(addedProcess)')
                            v-icon(style='font-size:20px;')
                              | mdi-delete
          v-card-actions.text-right(style='padding: 0px; display: flex; justify-content: center;')
            v-btn#precios-procedimientos-3-view.style-btn-sasve(v-if="user.selectAccount === 'personal' || user.accounts.length === 1" color='primary' @click='saveTable' :loading="loadingSave")
              strong GUARDAR CAMBIOS
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  export default {
    components: {

    },
    data: () => ({
      loadingSave: false,
      disabledText: false,
      // variables para confirmacion de item eliminado
      confirmationDialog: false,
      indexToDelete: -1,
      // variable para agregar procedimientos
      processesAddedArray: [],
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      currentDrId: firebase.auth().currentUser.uid,
      // variables para filtrar busqueda
      searchProcessTable: '',
      processFilter: [],
      searchProcessTableAdded: '',
      processesAddedFilter: [],
      // json de grupos de items
      allProcessesObject: {
        allProcessesArray: [
          {
            class: 'coro_acri_perm',
            label: 'Corona acrílica autocurado en diente permanente',
            value: 'coro_acri_perm',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Placa de acetato para blanqueamiento',
            value: 'plac_blan',
            class: 'plac_blan',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Blanqueamiento unimaxilar con peróxido de hidrógeno (casero)',
            value: 'blanc_uni_h',
            class: 'blanc_uni_h',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Blanqueamiento dentario unimaxilar en consultorio con laser',
            value: 'blanc_uni',
            class: 'blanc_uni',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Mocap por pieza',
            value: 'mocap',
            class: 'mocap',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Carilla directa de composite en diente definitivo',
            value: 'cari_compos_def',
            class: 'cari_compos_def',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Perno muñon colado',
            value: 'perno_col',
            class: 'perno_col',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Blanqueamiento de diente debilitado',
            value: 'blanc_debi',
            class: 'blanc_debi',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Blanqueamiento dentario casero (General)',
            value: 'blanc_case',
            class: 'blanc_case',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Blanqueamiento dentario en consultorio con laser (General)',
            value: 'blanc_denta_consul',
            class: 'blanc_denta_consul',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Carilla de cerámica E-max',
            value: 'cari_ceram_emax',
            class: 'cari_ceram_emax',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Carilla directa de composite en diente temporario',
            value: 'cari_compos_tempo',
            class: 'cari_compos_tempo',
            category: 'Estética',
            price: '',
          },
          // posiblemente sobrando sobra
          {
            label: 'Carilla directa de Composite',
            value: 'cari_compos',
            class: 'cari_compos',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Provisional para carilla',
            value: 'provi_cari',
            class: 'provi_cari',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Carilla indirecta de resina',
            value: 'cari_res',
            class: 'cari_res',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Carilla disilicato de litio',
            value: 'cari_disili',
            class: 'cari_disili',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Despigmentación de encías superior (General)',
            value: 'desp_enci_sup',
            class: 'desp_enci_sup',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Despigmentación de encías inferior (General)',
            value: 'desp_enci_inf',
            class: 'desp_enci_inf',
            category: 'Estética',
            price: '',
          },
          {
            label: 'Plasma rico en plaquetas',
            value: 'plasm_ric_plac',
            class: 'plasm_ric_plac',
            category: 'Estética',
            price: '',
          },
          {
            class: 'aplic_barni',
            label: 'Aplicación de barniz fluorado (General)',
            value: 'aplic_barni',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'terap_lase',
            label: 'Terapias con laser por sesión (General)',
            value: 'terap_lase',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'diag_ciru',
            label: 'Diagnóstico y plan de tratamiento para cirugía (General)',
            value: 'diag_ciru',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'diag_perio',
            label: 'Diagnóstico y plan de tratamiento para periodoncia (General)',
            value: 'diag_perio',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'diag_endo',
            label: 'Diagnóstico y plan de tratamiento para endodoncia (General)',
            value: 'diag_endo',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'diag_ortod',
            label: 'Diagnóstico y plan de tratamiento para ortodoncia (General)',
            value: 'diag_ortod',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'diag_rehabi',
            label: 'Diagnóstico y plan de tratamiento para rehabilitación (General)',
            value: 'diag_rehabi',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'ctrl_caries',
            label: 'Control de caries insipiente (General)',
            value: 'ctrl_caries',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'placa_bac',
            label: 'Control de placa bacteriana (General)',
            value: 'placa_bac',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'consul_urgen',
            label: 'Consulta odontológica de urgencia (General)',
            value: 'consul_urgen',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'consul_odont',
            label: 'Consulta Odontológica (General)',
            value: 'consul_odont',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'profil',
            label: 'Profilaxis (General)',
            value: 'profil',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'porfil_apli_flu',
            label: 'Profilaxis y aplicación de fluor (General)',
            value: 'porfil_apli_flu',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'reminer_profil',
            label: 'Remineralización + profilaxis (General)',
            value: 'reminer_profil',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'sed_medic',
            label: 'Sedación con medicamentos (General)',
            value: 'sed_medic',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'sed_ox',
            label: 'Sedación consciente con óxido nitroso y oxígeno (General)',
            value: 'sed_ox',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'treat_plan',
            label: 'Planificación de tratamiento (General)',
            value: 'treat_plan',
            category: 'Diagnóstico',
            price: '',
          },
          {
            class: 'prot_sop',
            label: 'Prótesis de carga inmediata implanto soportada',
            value: 'prot_sop',
            category: 'Implante',
            price: '',
          },
          {
            class: 'rege_guia',
            label: 'Regeneración osea guiada con aloinjerto',
            value: 'rege_guia',
            category: 'Implante',
            price: '',
          },
          {
            class: 'injer_hue',
            label: 'Injerto de hueso',
            value: 'injer_hue',
            category: 'Implante',
            price: '',
          },
          {
            class: 'injer_auto',
            label: 'Injerto con hueso autógeno',
            value: 'injer_auto',
            category: 'Implante',
            price: '',
          },
          {
            class: 'impl_osteo',
            label: 'Implante osteointegrado por unidad',
            value: 'impl_osteo',
            category: 'Implante',
            price: '',
          },
          {
            class: 'impl_ort',
            label: 'Implante ortodóntico',
            value: 'impl_ort',
            category: 'Implante',
            price: '',
          },
          {
            class: 'coro_prov_impl_carg',
            label: 'Corona provisoria sobre implante con carga inmediata',
            value: 'coro_prov_impl_carg',
            category: 'Implante',
            price: '',
          },
          {
            class: 'remo_implan_no_ose',
            label: 'Remoción de implante dentario no oseo integrado',
            value: 'remo_implan_no_ose',
            category: 'Implante',
            price: '',
          },
          {
            class: 'remo_implan_ose',
            label: 'Remoción de implante dentario oseo integrado',
            value: 'remo_implan_ose',
            category: 'Implante',
            price: '',
          },
          {
            class: 'impl_pers',
            label: 'Implante personalizado',
            value: 'impl_pers',
            category: 'Implante',
            price: '',
          },
          {
            class: 'radio_bite_wing',
            label: 'Radiografía coronal - bite-wing',
            value: 'radio_bite_wing',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'radio_oclu',
            label: 'Radiografía oclusal (General)',
            value: 'radio_oclu',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'estu_cefa',
            label: 'Estudio cefalométrico/teleradiografía (General)',
            value: 'estu_cefa',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'radio_peria',
            label: 'Radiografía periapical digital',
            value: 'radio_peria',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'radio_pano',
            label: 'Radiografía panorámica (General)',
            value: 'radio_pano',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'radio_cef',
            label: 'Radiografía cefálica lateral (General)',
            value: 'radio_cef',
            category: 'Rayos X',
            price: '',
          },
          {
            class: 'exod_indi',
            label: 'Exodoncia indicada ortodoncia / prótesis',
            value: 'exod_indi',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'biop',
            label: 'Biopsia (General)',
            value: 'biop',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'super_num',
            label: 'Exodoncia de supernumerario',
            value: 'super_num',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'cirug_impact',
            label: 'Cirugía de dientes incluidos / impactados por pieza',
            value: 'cirug_impact',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'cirug_tumor',
            label: 'Cirugía - remoción de tumores benignos de tejidos blandos',
            value: 'cirug_tumor',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'cirug_perico',
            label: 'Cirugía para tratamiento de pericoronaritis',
            value: 'cirug_perico',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'cirug_odontom',
            label: 'Cirugía para remoción de odontoma',
            value: 'cirug_odontom',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'exod_raiz',
            label: 'Exodoncia de raíz residual',
            value: 'exod_raiz',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'exod_tempo',
            label: 'Exodoncia de restos radiculares temporarios',
            value: 'exod_tempo',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'exod_piez',
            label: 'Exodoncia por pieza',
            value: 'exod_piez',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'exod_deci',
            label: 'Exodoncia simple de diente decíduo',
            value: 'exod_deci',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'fren_lab',
            label: 'Frenectomía labial',
            value: 'fren_lab',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'esci_mucosele',
            label: 'Escisión de mucosele',
            value: 'esci_mucosele',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'fren_lin',
            label: 'Frenectomía lingual',
            value: 'fren_lin',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'levan_liof',
            label: 'Levantamiento de seno maxilar con hueso liofilizado',
            value: 'levan_liof',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'odon_sec',
            label: 'Odonto-sección',
            value: 'odon_sec',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'prof_vesti',
            label: 'Profundización de vestíbulo',
            value: 'prof_vesti',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'regul_rebor',
            label: 'Regularización de reborde alveolar (alveoloplastía) por elemento',
            value: 'regul_rebor',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'biche',
            label: 'Bichectomía (General)',
            value: 'biche',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'tor_pal',
            label: 'Torus palatino',
            value: 'tor_pal',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'tor_mand',
            label: 'Torus mandibular - unilateral',
            value: 'tor_mand',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'api_uni_sin',
            label: 'Apicectomía unirradicular sin obturación retrogada',
            value: 'api_uni_sin',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'api_uni_con',
            label: 'Apicectomía unirradicular con obturación retrogada ',
            value: 'api_uni_con',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'opercu',
            label: 'Operculectomía',
            value: 'opercu',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'ciru_lev_sen',
            label: 'Cirugía de levantamiento de seno bilateral (General)',
            value: 'ciru_lev_sen',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'reg_premax',
            label: 'Regeneración de premaxila (General)',
            value: 'reg_premax',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'colo_hues',
            label: 'Colocación de hueso',
            value: 'colo_hues',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'inje_membra',
            label: 'Injerto de membrana',
            value: 'inje_membra',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'colo_membra',
            label: 'Colocación de membrana',
            value: 'colo_membra',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'cirugi',
            label: 'Cirugía',
            value: 'cirugi',
            category: 'Cirugía',
            price: '',
          },
          {
            class: 'descen',
            label: 'Descensibilización por pieza',
            value: 'descen',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'prep_nucle',
            label: 'Preparo para núcleo intrarradicular',
            value: 'prep_nucle',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'protec_pulp',
            label: 'Protección pulpar directa',
            value: 'protec_pulp',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'pulpec_deci',
            label: 'Pulpectomía en diente deciduo - temporal',
            value: 'pulpec_deci',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'pulpec_uni',
            label: 'Pulpectomía unirradicular',
            value: 'pulpec_uni',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'retrat_bi',
            label: 'Re-tratamiento endodóntico birradicular',
            value: 'retrat_bi',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'retrat_multi',
            label: 'Re-tratamiento endodóntico multirradicular',
            value: 'retrat_multi',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'retrat_uni',
            label: 'Re-tratamiento endodóntico unirradicular',
            value: 'retrat_uni',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'trata_uni',
            label: 'Tratamiento endodóntico unirradicular',
            value: 'trata_uni',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'trat_bi_prim',
            label: 'Tratamiento endodóntico birradicular',
            value: 'trat_bi_prim',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'trata_multi',
            label: 'Tratamiento endodóntico multirradicular',
            value: 'trata_multi',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'trat_end_deci',
            label: 'Tratamiento de endodoncia en diente deciduo',
            value: 'trat_end_deci',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'trat_end_mol_deci',
            label: 'Tratamiento de conducto en molar deciduo',
            value: 'trat_end_mol_deci',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'rebasc',
            label: 'Rebascularización',
            value: 'rebasc',
            category: 'Endodoncia',
            price: '',
          },
          {
            class: 'rep_brac',
            label: 'Reposición de bracket',
            value: 'rep_brac',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ort_bima',
            label: 'Ortodoncia bimaxilar (General)',
            value: 'ort_bima',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ort_ling',
            label: 'Ortodoncia lingual (General)',
            value: 'ort_ling',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'elim_brack',
            label: 'Eliminación de brackets superior/inferior (General)',
            value: 'elim_brack',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'educ_ling',
            label: 'Educador lingual',
            value: 'educ_ling',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'cont_fija',
            label: 'Contención fija por arcada superior/inferior',
            value: 'cont_fija',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'cem_brac_aut_est',
            label: 'Cementación de bracket autoligado nuevo estético',
            value: 'cem_brac_aut_est',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'cem_brac_aut',
            label: 'Cementación de brackets autoligado nuevo',
            value: 'cem_brac_aut',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ceme_brac',
            label: 'Cementación de brackets convencional nuevo',
            value: 'ceme_brac',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'bio_balt',
            label: 'Bionato de Balters (General)',
            value: 'bio_balt',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'bi_he',
            label: 'Bi-helix',
            value: 'bi_he',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'arco_fijo',
            label: 'Arco lingual fijo',
            value: 'arco_fijo',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'barra_trans',
            label: 'Barra transpalatino fija',
            value: 'barra_trans',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'parrill_habit',
            label: 'Parrilla hábitos / dedo - deglusión atípica (General)',
            value: 'parrill_habit',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'extra_seria',
            label: 'Extracción seriada (General)',
            value: 'extra_seria',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'feru_perma',
            label: 'Férula en dientes permanentes por pieza',
            value: 'feru_perma',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'feru_plast',
            label: 'Férula de acetato (General)',
            value: 'feru_plast',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_uni',
            label: 'Ortodoncia unimaxilar (General)',
            value: 'ortod_uni',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'plac_haw',
            label: 'Placa de Hawley (General)',
            value: 'plac_haw',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_conv_est_sup',
            label: 'Ortodoncia convencional estético sup. (General)',
            value: 'ortod_conv_est_sup',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_conv_est_inf',
            label: 'Ortodoncia convencional estético inf. (General)',
            value: 'ortod_conv_est_inf',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_conv_met_sup',
            label: 'Ortodoncia convencional metálico sup. (General)',
            value: 'ortod_conv_met_sup',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_conv_met_inf',
            label: 'Ortodoncia convencional metálico inf. (General)',
            value: 'ortod_conv_met_inf',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_aut_met_sup',
            label: 'Ortodoncia autoligado metálico sup. (General)',
            value: 'ortod_aut_met_sup',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_aut_met_inf',
            label: 'Ortodoncia autoligado metálico inf. (General)',
            value: 'ortod_aut_met_inf',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_aut_est_sup',
            label: 'Ortodoncia autoligado estético sup. (General)',
            value: 'ortod_aut_est_sup',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_aut_est_inf',
            label: 'Ortodoncia autoligado estético inf. (General)',
            value: 'ortod_aut_est_inf',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ferul_plast',
            label: 'Férula plástica (General)',
            value: 'ferul_plast',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'masc_fac',
            label: 'Máscara facial (General)',
            value: 'masc_fac',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'pist_plan',
            label: 'Pistas planas (General)',
            value: 'pist_plan',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'plac_levan',
            label: 'Placa con levante de mordida (General)',
            value: 'plac_levan',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'plan_incl',
            label: 'Plano inclinado (General)',
            value: 'plan_incl',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'quad_hel',
            label: 'Quad-helix (General)',
            value: 'quad_hel',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_uni_ling',
            label: 'Ortodoncia unimaxilar sup/inf lingual (General)',
            value: 'ortod_uni_ling',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_uni_ali_sup',
            label: 'Ortodoncia unimaxilar sup con alineadores (General)',
            value: 'ortod_uni_ali_sup',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'ortod_uni_ali_inf',
            label: 'Ortodoncia unimaxilar inf con alineadores (General)',
            value: 'ortod_uni_ali_inf',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'control_ort',
            label: 'Control de ortodoncia (General)',
            value: 'control_ort',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'camb_ligas',
            label: 'Cambio de ligas (General)',
            value: 'camb_ligas',
            category: 'Ortodoncia',
            price: '',
          },
          {
            class: 'prov_rest',
            label: 'Provisional para restauraciones',
            value: 'prov_rest',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'pern_fibra',
            label: 'Perno muñón fibra de vidrio',
            value: 'pern_fibra',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'resta_compu',
            label: 'Restauración en resina fotopolimerizable compuesta',
            value: 'resta_compu',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'resta_comple',
            label: 'Restauración en resina fotopolimerizable compleja',
            value: 'resta_comple',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'resta_simp',
            label: 'Restauración en resina fotopolimerizable simple',
            value: 'resta_simp',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'resta_prov',
            label: 'Restauración provisional con eugenolato',
            value: 'resta_prov',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'ajust_oclu',
            label: 'Ajuste oclusal (General)',
            value: 'ajust_oclu',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'ajust_rest',
            label: 'Ajuste oclusal con restauraciones para guías caninas (General)',
            value: 'ajust_rest',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'obtur_prov',
            label: 'Obturación provicional',
            value: 'obtur_prov',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'rest_io_compue',
            label: 'Restauración con ionómero de vidrio',
            value: 'rest_io_compue',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'rest_ang',
            label: 'Restauración de ángulos en resina',
            value: 'rest_ang',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'rest_inci',
            label: 'Restauración de borde incisal',
            value: 'rest_inci',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'aplic_inv',
            label: 'Aplicación de sellante - técnica invasiva',
            value: 'aplic_inv',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'aplic_fisur',
            label: 'Aplicación de sellantes de fosas y fisuras',
            value: 'aplic_fisur',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'pulido_cer',
            label: 'Pulido cervical',
            value: 'pulido_cer',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'eleva_marge',
            label: 'Elevación de margen ',
            value: 'eleva_marge',
            category: 'Restauración',
            price: '',
          },
          {
            class: 'inc_met_on',
            label: 'Incrustación de metal onlay',
            value: 'inc_met_on',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'inc_met_in',
            label: 'Incrustación de metal inlay',
            value: 'inc_met_in',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'inc_cer_on',
            label: 'Incrustación de cerámica onlay',
            value: 'inc_cer_on',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'inc_cer_in',
            label: 'Incrustación de cerámica inlay',
            value: 'inc_cer_in',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_tot_car',
            label: 'Prótesis total caracterizada (General)',
            value: 'prot_tot_car',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_par_sup_crom',
            label: 'Prótesis parcial removible de cromo-cobalto superior (General)',
            value: 'prot_par_sup_crom',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_par_inf_crom',
            label: 'Prótesis parcial removible de cromo-cobalto inferior (General)',
            value: 'prot_par_inf_crom',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_met_cer',
            label: 'Corona metal cerámica',
            value: 'coro_met_cer',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'ceme_coro',
            label: 'Cementación de corona',
            value: 'ceme_coro',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_ace_deci',
            label: 'Corona de acero en diente decíduo',
            value: 'coro_ace_deci',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_acri_termo',
            label: 'Corona de acrílico termocurado',
            value: 'coro_acri_termo',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_zirco',
            label: 'Corona de zircónio',
            value: 'coro_zirco',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_emax',
            label: 'Corona con E-max',
            value: 'coro_emax',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_meta_impla',
            label: 'Corona libre de metal sobre implante de cerámica por pieza',
            value: 'coro_meta_impla',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_prov_implan',
            label: 'Corona provisional sobre implante',
            value: 'coro_prov_implan',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_prov_per',
            label: 'Corona provisional con perno',
            value: 'coro_prov_per',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_prov_acri',
            label: 'Corona provisional de acrílico autocurable',
            value: 'coro_prov_acri',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'incrus_ceram',
            label: 'Incrustación de cerámica E-max overlay',
            value: 'incrus_ceram',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'incrus_resi_in',
            label: 'Incrustación en resina (indirecta) - inlay',
            value: 'incrus_resi_in',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'incrus_resi_on',
            label: 'Incrustación en resina (indirecta) - onlay',
            value: 'incrus_resi_on',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'mall_prot',
            label: 'Malla adicional para prótesis (General)',
            value: 'mall_prot',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'on_resi_indi',
            label: 'Onlay de resina indirecta',
            value: 'on_resi_indi',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'plac_estab',
            label: 'Placa oclusal estabilizadora (General)',
            value: 'plac_estab',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'plac_posi',
            label: 'Placa oclusal posicionadora (General)',
            value: 'plac_posi',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'profil_trat',
            label: 'Profilaxis - tartrectomía (General)',
            value: 'profil_trat',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_fij_emax',
            label: 'Prótesis fija adhesiva en cerámica E-max press por pieza (metal free) (General)',
            value: 'prot_fij_emax',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_fij_direc',
            label: 'Prótesis fija adhesiva directa (provisional) (General)',
            value: 'prot_fij_direc',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_meta',
            label: 'Prótesis fija metal cerámica (General)',
            value: 'prot_meta',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_fij_piez',
            label: 'Prótesis parcial fija provisoria por pieza',
            value: 'prot_fij_piez',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_par_inf',
            label: 'Prótesis parcial removible acrílica inferior (General)',
            value: 'prot_par_inf',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_par_sup',
            label: 'Prótesis parcial removible acrílica superior (General)',
            value: 'prot_par_sup',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_inme',
            label: 'Prótesis total inmediata (General)',
            value: 'prot_inme',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'provi_inon',
            label: 'Provisional para inlay/onlay',
            value: 'provi_inon',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'puen_meta_ceram',
            label: 'Puente fijo metal cerámica (General)',
            value: 'puen_meta_ceram',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'puen_meta_zirco',
            label: 'Puente fijo metal zirconio (General)',
            value: 'puen_meta_zirco',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'puen_zirco',
            label: 'Puente fijo zirconio (General)',
            value: 'puen_zirco',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'repa_prot',
            label: 'Reparación de prótesis total (exclusivamente en consultorio) (General)',
            value: 'repa_prot',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'rebas_prot_inme_consul',
            label: 'Rebase de prótesis total o parcial - inmediato (en consultorio) (General)',
            value: 'rebas_prot_inme_consul',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'remo_per',
            label: 'Remoción de perno intrarradicular',
            value: 'remo_per',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'repa_prot_par_remo',
            label: 'Reparación de prótesis parcial removible (en consultorio y laboratorio) (General)',
            value: 'repa_prot_par_remo',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'repa_prot_tot',
            label: 'Reparación de prótesis total (en consultorio y laboratorio) (General)',
            value: 'repa_prot_tot',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_tot_infsup',
            label: 'Prótesis total sup/inf (General)',
            value: 'prot_tot_infsup',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'dent_inf_clic',
            label: 'Sobre dentadura inferior con barra clic (General)',
            value: 'dent_inf_clic',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'camb_dien_pro',
            label: 'Cambio de dientes en prótesis',
            value: 'camb_dien_pro',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prot_par_prov',
            label: 'Prótesis parcial sup / inf acrílica provisional (General)',
            value: 'prot_par_prov',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prote_proto_sup',
            label: 'Prótesis protocolo superior (General)',
            value: 'prote_proto_sup',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'prote_proto_inf',
            label: 'Prótesis protocolo inferior (General)',
            value: 'prote_proto_inf',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_met_cer_impl',
            label: 'Corona metal cerámica sobre implante por pieza',
            value: 'coro_met_cer_impl',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'ret_ack_acri',
            label: 'Retenedor acker acrílico ',
            value: 'ret_ack_acri',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'ret_ack_cr_co',
            label: 'Retenedor acker Cro-Co',
            value: 'ret_ack_cr_co',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'corona_pmma',
            label: 'Corona en PMMA',
            value: 'corona_pmma',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_res_dir',
            label: 'Corona de resina directa',
            value: 'coro_res_dir',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_res_ind',
            label: 'Corona de resina indirecta',
            value: 'coro_res_ind',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'coro_prot_ging',
            label: 'Corona de prótesis dentogingival (General)',
            value: 'coro_prot_ging',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'ret_coro',
            label: 'Retiro de corona',
            value: 'ret_coro',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'ret_puen',
            label: 'Retiro de puentes (General)',
            value: 'ret_puen',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'cari_fel',
            label: 'Carilla Feldespática (folio de platino refractario) ',
            value: 'cari_fel',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'incrus_cerama',
            label: 'Incrustación en Ceramage',
            value: 'incrus_cerama',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'overd_sup',
            label: 'Overdenture superior (General)',
            value: 'overd_sup',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'barr_hol_sup',
            label: 'Barra holder superior (General)',
            value: 'barr_hol_sup',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'remo_pont',
            label: 'Remoción de póntico',
            value: 'remo_pont',
            category: 'Prótesis',
            price: '',
          },
          {
            class: 'higie_prot',
            label: 'Higienización de prótesis protocolo (General)',
            value: 'higie_prot',
            category: 'Higiene',
            price: '',
          },
          {
            class: 'desen_uni_sup',
            label: 'Desensibilizante unimaxilar superior (General)',
            value: 'desen_uni_sup',
            category: 'Higiene',
            price: '',
          },
          {
            class: 'desen_uni_inf',
            label: 'Desensibilizante unimaxilar inferior (General)',
            value: 'desen_uni_inf',
            category: 'Higiene',
            price: '',
          },
          {
            class: 'rasp_aliz_sext',
            label: 'Raspado y alizado por sextante',
            value: 'rasp_aliz_sext',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'alarg_coro',
            label: 'Alargamiento de corona por pieza',
            value: 'alarg_coro',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'cirug_perio',
            label: 'Cirugía periodontal menor por pieza',
            value: 'cirug_perio',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'gingiv',
            label: 'Gingivectomía por pieza',
            value: 'gingiv',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'injer_teji',
            label: 'Injerto de tejido conectivo por pieza',
            value: 'injer_teji',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'injer_gingiv',
            label: 'Injerto gingival libre',
            value: 'injer_gingiv',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'rasp_radic',
            label: 'Raspado y alisado radicular por pieza',
            value: 'rasp_radic',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'rege_auto',
            label: 'Regeneración ósea guiada con hueso autólogo',
            value: 'rege_auto',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'trat_abse',
            label: 'Tratamiento de absceso periodontal agudo',
            value: 'trat_abse',
            category: 'Periodoncia',
            price: '',
          },
          {
            class: 'feru_deci',
            label: 'Férula en dientes decíduos por pieza',
            value: 'feru_deci',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'coro_acri_deci',
            label: 'Corona acrílica autocurado en diente decíduo',
            value: 'coro_acri_deci',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'mante_fijo',
            label: 'Mantenedor de espacio fijo (anza distal / mesial)',
            value: 'mante_fijo',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'mante_removi_inf',
            label: 'Mantenedor de espacio removible inferior',
            value: 'mante_removi_inf',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'mante_removi_sup',
            label: 'Mantenedor de espacio removible superior',
            value: 'mante_removi_sup',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'fer_tempo',
            label: 'Férula por pieza en dientes temporarios',
            value: 'fer_tempo',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'btn_nan',
            label: 'Botón de Nance',
            value: 'btn_nan',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'estimu',
            label: 'Citas de estimulación por 3 citas (General)',
            value: 'estimu',
            category: 'Odontopediatría',
            price: '',
          },
          {
            class: 'ortop',
            label: 'Ortopedia',
            value: 'ortop',
            category: 'Odontopediatría',
            price: '',
          },
        ],
      },
    }),
    computed: {
      lstTreatmentsPrices () {
        return this.$store.state.treatment.lstTreatmentsPrices
      },
      user () {
        return this.$store.state.users.user
      },
    },
    watch: {
      user () {
        if (!this.user.selectAccount === 'personal') {
          this.disabledText = true
        } else {
          this.disabledText = false
        }
      },
      lstTreatmentsPrices () {
        this.processesAddedArray = this.lstTreatmentsPrices
        this.processesAddedFilter = this.processesAddedArray
      },
    },
    created () {
      this.processFilter = this.allProcessesObject.allProcessesArray
      this.processesAddedArray = this.lstTreatmentsPrices
      this.processesAddedFilter = this.processesAddedArray
      // let arr = []
      // const user = this.$store.state.users.user
      // firebase.database().ref(`${user.selectAccount}/treatmentPrices/${this.currentDrId}`).once('value', (snapshot) => {
      //   if (snapshot.val()) {
      //     const res = snapshot.val()
      //     this.processesAddedArray = res
      //   }
      // }).then(() => {
      //   this.processesAddedFilter = this.processesAddedArray
      // }).catch(() => {
      //   this.snackbar = {
      //     show: true,
      //     color: 'red',
      //     text: 'Ocurrió un problema al cargar los datos',
      //   }
      // })
    },
    methods: {
      addProcess (process) {
        const processAddedIndex = this.processesAddedArray.findIndex(processElement => processElement.label === process.label)
        if (processAddedIndex === -1) {
          this.processesAddedArray.push(process)
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El tratamiento seleccionado ya fue agregado',
          }
        }
      },
      deleteProcess (addedProcess) {
        this.confirmationDialog = true
        this.indexToDelete = this.processesAddedArray.indexOf(addedProcess)
      },
      acceptConfirmation () {
        this.processesAddedArray.splice(this.indexToDelete, 1)
        this.indexToDelete = -1
        this.confirmationDialog = false
      },
      searchProcess () {
        this.processFilter = this.allProcessesObject.allProcessesArray.filter(process => {
          return (
            (process.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.searchProcessTable.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) ||
            (process.category.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.searchProcessTable.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()))
          )
        })
      },
      searchProcessAdded () {
        this.processesAddedFilter = this.processesAddedArray.filter(process => {
          return (
            (process.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.searchProcessTableAdded.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) ||
            (process.category.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.searchProcessTableAdded.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) ||
            (process.price.normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.searchProcessTableAdded.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()))
          )
        })
      },
      saveTable () {
        let allFieldsFilled = true
        let allPricesPositive = true
        for (let i = 0; i < this.processesAddedArray.length; i++) {
          if (this.processesAddedArray[i].price === '') {
            allFieldsFilled = false
          }
          if (this.processesAddedArray[i].price < 0) {
            allPricesPositive = false
          }
        }
        if (allFieldsFilled === true) {
          if (allPricesPositive === true) {
            const user = this.$store.state.users.user
            let path = ''
            if (user.selectAccount === 'personal') {
              path = `${user.selectAccount}/treatmentPrices/${this.currentDrId}`
            } else {
              path = `${user.selectAccount}/treatmentPrices`
            }
            this.loadingSave = true
            firebase.database().ref(path).set(this.processesAddedArray).then(() => {
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Los cambios se guardaron con éxito',
              }
              this.loadingSave = false
            }).catch(() => {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ocurrió un problema al guardar los datos',
              }
              this.loadingSave = false
            })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Lo sentimos, no puedes agregar precios negativos',
            }
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes asignar precio a todos los procedimientos agregados',
          }
        }
      },
    },
  }
</script>

<style lang="scss">
.tables{
  font-size: 1.2rem;
}
  .style-btn-sasve {
    width: 30%;
  }
  @media (max-width: 768px) {
    .style-btn-sasve {
      width: 100%;
    }
  }
</style>
